.test-aimation-container{
  margin-top: 100px;
  .test-animation{
    width: 226px;
    height: 340px;
    background-image: url("/assets/v2/images/testanimation/1.jpg");
    animation: testanimation2 infinite 600ms;
  }

  .test-animation2{
    width: 226px;
    height: 340px;
    background-image: url("/assets/v2/images/testanimation/1.jpg");
    animation: testanimation infinite 1300ms;
  }

}

@keyframes testanimation {

  0.1%,10%{background-position: 0px 0px}
  10.1%,20%{background-position: 0px 350px}
  20.1%,30%{background-position: 0px 700px}
  30.1%,40%{background-position: 0px 1050px}
  40.1%,50%{background-position: 0px 1400px}
  50.1%,60%{background-position: 0px 1750px}
  60.1%,70%{background-position: 0px 2100px}
  70.1%,80%{background-position: 0px 2450px}
  80.1%,90%{background-position: 0px 2800px}
  90.1%,100%{background-position: 0px 3150px}

}


@keyframes testanimation2 {

  0.1%,20%{background-position: 0px 0px}
  20.1%,40%{background-position: 0px 350px}
  40.1%,60%{background-position: 0px 700px}
  60.1%,80%{background-position: 0px 1050px}
  80.1%,100%{background-position: 0px 1400px}

}
