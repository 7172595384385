/* CSS HEX */
/*--smoky-black: #0E0D0Aff;
--light-coral: #FA7A76ff;
--dark-red: #8D0C03ff;
--antique-bronze: #615710ff;
--gold-web-golden: #F9D704ff;

/* CSS HSL */ /*
--smoky-black: hsla(45, 17%, 5%, 1);
--light-coral: hsla(2, 93%, 72%, 1);
--dark-red: hsla(4, 96%, 28%, 1);
--antique-bronze: hsla(53, 72%, 22%, 1);
--gold-web-golden: hsla(52, 97%, 50%, 1);
*/
/* SCSS HEX */
$smoky-black: #0e0d0aff;
$light-coral: #fa7a76ff;
$dark-red: #8d0c03ff;
$antique-bronze: #615710ff;
$gold-web-golden: #f9d704ff;

/* SCSS HSL */
$smoky-black: hsla(45, 17%, 5%, 1);
$light-coral: hsla(2, 93%, 72%, 1);
$dark-red: hsla(4, 96%, 28%, 1);
$antique-bronze: hsla(53, 72%, 22%, 1);
$gold-web-golden: hsla(52, 97%, 50%, 1);

/* SCSS RGB */
$smoky-black: rgba(14, 13, 10, 1);
$light-coral: rgba(250, 122, 118, 1);
$dark-red: rgba(141, 12, 3, 1);
$antique-bronze: rgba(97, 87, 16, 1);
$gold-web-golden: rgba(249, 215, 4, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #0e0d0aff, #fa7a76ff, #8d0c03ff, #615710ff, #f9d704ff);
$gradient-right: linear-gradient(90deg, #0e0d0aff, #fa7a76ff, #8d0c03ff, #615710ff, #f9d704ff);
$gradient-bottom: linear-gradient(180deg, #0e0d0aff, #fa7a76ff, #8d0c03ff, #615710ff, #f9d704ff);
$gradient-left: linear-gradient(270deg, #0e0d0aff, #fa7a76ff, #8d0c03ff, #615710ff, #f9d704ff);
$gradient-top-right: linear-gradient(45deg, #0e0d0aff, #fa7a76ff, #8d0c03ff, #615710ff, #f9d704ff);
$gradient-bottom-right: linear-gradient(135deg, #0e0d0aff, #fa7a76ff, #8d0c03ff, #615710ff, #f9d704ff);
$gradient-top-left: linear-gradient(225deg, #0e0d0aff, #fa7a76ff, #8d0c03ff, #615710ff, #f9d704ff);
$gradient-bottom-left: linear-gradient(315deg, #0e0d0aff, #fa7a76ff, #8d0c03ff, #615710ff, #f9d704ff);
$gradient-radial: radial-gradient(#0e0d0aff, #fa7a76ff, #8d0c03ff, #615710ff, #f9d704ff);

:root {
  // find nearest restauran color
  --ta-dark-blue: #f03f3c;
  --ta-light-blue: #f03f3c;

  --ta-bright-blue: #32859f;
  --ta-creamy: #f6f5f1;
  --ta-white: #ffffff;
  --ta-orange: #fa8208;
  --ta-dark-gray: #7d7d7d;
  --ta-light-gray: #bfbfbf;

  // theme element color (buttons, icons, checkboxes and stuff)
  --ta-primary-color: #ffd200;
  --ta-primary-color-web: #f03f3c;
  // this is primry color rgb value
  --ta-primary-text-bg: 255, 210, 0; // 11, 136, 216;

  --ta-primary-link: #eb4d4b;
  --ta-secondary-text: #838383;
  --ta-bg-color: #f6f6f6;

  //--ta-primary-color: #ff071c; //#0b88d8;
  // this is primry color rgb value
  // --ta-primary-text-bg: 255, 7, 28; //11, 136, 216;
}

.color-dark-gary {
  color: var(--ta-dark-gray);
}
.color-light-gary {
  color: var(--ta-light-gray);
}

.font-size-small {
  font-size: 13px;
}

.text-justify {
  text-align: justify;
}

.font-weight-bold {
  font-weight: bold;
}

@font-face {
  font-family: poppins;
  src: url("fonts/popins/Poppins-Medium.ttf");
  font-weight: Medium;
}

@font-face {
  font-family: poppins;
  src: url("fonts/popins/Poppins-Bold.ttf");
  font-weight: Bold;
}

@font-face {
  font-family: poppins;
  src: url("fonts/popins/Poppins-SemiBold.ttf");
  font-weight: SemiBold;
}

@font-face {
  font-family: poppins;
  src: url("fonts/popins/Poppins-Thin.ttf");
  font-weight: Thin;
}

@font-face {
  font-family: poppins;
  src: url("fonts/popins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Roboto;
  src: url("fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: Rubik;
  src: url("fonts/Rubik-VariableFont_wght.ttf");
}

@font-face {
  font-family: junegull;
  src: url("fonts/junegull.ttf");
}

@font-face {
  font-family: Roboto;
  font-weight: bold;
  src: url("fonts/Roboto-bold.ttf");
}

.small-title {
  font-size: 13px;
  color: #a6a6a6;
}

.default-button {
  --background: var(--ta-light-blue);
  --border-radius: 3px;
  font-weight: bold;
  height: 40px;
}

input {
  color: #515151;
  border-radius: 3px;
  outline: 2px solid transparent;
  transition: 0.3s;
  &:focus {
    outline: 2px solid var(--ta-light-blue);
  }
}
