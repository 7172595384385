.v2-dots-container {
  &.first-dots {
    .v2-dots-inner {
      .v2-dots-layer2 {
        animation: dots-1 infinite 6s;
      }
    }
  }

  &.second-dots {
    .v2-dots-inner {
      .v2-dots-layer2 {
        animation: dots-2 infinite 6s;
      }
    }
  }

  &.third-dots {
    .v2-dots-inner {
      .v2-dots-layer2 {
        animation: dots-3 infinite 6s;
      }
    }
  }

}

@keyframes dots-1 {

  0%{left : -100%;opacity: 1}
  16%{left: 0%;}
  33%{left: 0%;}
  85%{
    left: 0%;
    opacity: 1;
  }
  100%{
    left: 0%;
    opacity: 0;
  }

}

@keyframes dots-2 {

  0%{left : -100%;opacity: 1}
  16%{left: -100%;}
  33%{left: 0%;}
  85%{
    left: 0%;
    opacity: 1;
  }
  100%{
    left: 0%;
    opacity: 0;
  }

}

@keyframes dots-3 {

  0%{left : -100%;opacity: 1}
  16%{left: -100%;}
  33%{left: -100%;}
  50%{
    left: 0%;
    opacity: 1;
    opacity: 1;
  }

  85%{
    left: 0%;
    opacity: 1;
  }
  100%{
    left: 0%;
    opacity: 0;
  }

}
