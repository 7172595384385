.plt-desktop {
  body {
    .ion-page {
      .side-bar {

        .side-header {
          font-size: 22px;
          letter-spacing: 3px;
          font-weight: bold;
          font-family: Yanone;
          ion-icon{
            font-size: 24px !important;
            margin-bottom: -7px !important;
          }
        }

        .side-content {
          ul {
            li {


            }
          }
        }
      }
    }

  }
}
