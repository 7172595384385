.v2-btn {
  background-color: transparent;
  color: var(--v2-red);
  font-size: 16px;
  font-family: poppins;
  ion-icon{
    font-size: 20px;
    margin-bottom: -5px;
  }
}

.v2-button-bottom{
  font-size: 12px;
  margin-top: -5px;
  color: var(--v2-black);
  opacity: 0.5;
}

.v2-icon{
  font-size: 24px;
}

.v2-button-item{
  color: var(--v2-red);
  font-family: poppins;
  cursor: pointer;
}

.v2-icon-arrow {
  font-size: 20px;
  margin-bottom: -5px;
}



.my-class .alert-wrapper{
  .default-item {
    --background: red;
    --background-hover: transparent;
    --color: inherit;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --inner-padding-top: 0;
    --inner-padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --min-height: 5px;
    --background-activated: red;
    --background-activated-opacity: 1;
  }
}

.color-red{
  color: var(--v2-red);
  font-family: Poppins;
}

.language-icon{
    margin-right: 10px;
    margin-bottom: -5px;
}

.language-change-select{
  width: 120px;
  ion-select{
    width: auto;
    margin: unset;
    max-width: unset;
    --placeholder-color: red;
    font-family: poppins;
    padding-left: 0;
    font-family: poppins;
    .select-text {
      -ms-flex: 1;
      flex: 1;
      min-width: 16px;
      font-size: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: unset;
      width: 150px;
      color: red;
    }
  }
}
